import React, { useMemo } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
    IPopupProps,
    PopupWrapperElement,
    PrimaryText,
    ButtonElement,
} from "nirvana-react-elements"

import { runtimeSelector } from "../../selectors/runtime.selector"
import { CALCULATOR_CHECKOUT_CONFIG } from "../../config/calculatorCheckout.config"
import { EmbedCalculatorEditTherapistsComponent } from "../embedCalculator/embedCalculatorEditTherapists.component"
import { embedCalculatorSelector } from "../../selectors/embedCalculator.selector"
import { embedCalculatorActionCreators } from "../../actions/embedCalculator.actions"
import { ToastrHelper } from "../../helpers/toastr.helper"

import closeIcon from "../../assets/images/icons/close-dark.svg"

export const EmbedCalculatorEditTherapistsPopupComponent: React.FunctionComponent<
    IPopupProps
> = props => {
    const navigate = useNavigate()

    const {
        handleSubmit,
        formState: { errors },
        control,
        register,
        unregister,
        setValue,
        watch,
    } = useForm({
        defaultValues: {
            [CALCULATOR_CHECKOUT_CONFIG.inputDataTherapistsKey]: [],
        },
    })

    const dispatch = useDispatch()

    const runtimeState = useSelector(runtimeSelector)
    const embedCalculatorState = useSelector(embedCalculatorSelector)

    const predefinedHealthProviders = useMemo<
        ICalculatorCheckoutSingleTherapistData[]
    >(() => {
        if (!embedCalculatorState.checkedEmbedKey?.healthProviderRates.length) {
            return []
        }

        return embedCalculatorState.checkedEmbedKey.healthProviderRates.map(
            item => ({
                healthProvider: item.healthProvider,
                healthProviderPreferredName: item.healthProvider.preferredName,
                rates: item.rates.map(rate => ({
                    sessionRate: Math.floor(rate.sessionRate / 100).toString(),
                    cptCode: rate.cptCode.value,
                })),
            }),
        )
    }, [embedCalculatorState.checkedEmbedKey])

    const onSaveHealthProviders = data => {
        if (!embedCalculatorState.managementKey) {
            return
        }

        // Since some items can be empty if we remove
        const therapistsData: ICalculatorCheckoutSingleTherapistData[] = data[
            CALCULATOR_CHECKOUT_CONFIG.inputDataTherapistsKey
        ].filter(item => !!item)

        if (
            therapistsData.length <
            CALCULATOR_CHECKOUT_CONFIG.minHealthProvidersCount
        ) {
            return
        }

        dispatch(
            embedCalculatorActionCreators.editHealthProviders(
                embedCalculatorState.managementKey,
                therapistsData,
                () => {
                    props.onDone()

                    ToastrHelper.success(
                        "Congratulations! Therapists were updated",
                    )
                },
                navigate,
            ),
        )
    }

    return (
        <PopupWrapperElement
            isActive={props.isActive}
            className={props.className}
            onDone={props.onDone}
            closeOnOutsideClick={props.closeOnOutsideClick}
            popupWidthClassName="w-720px"
        >
            <div
                className="
                    absolute right-30px top-30px cursor-pointer
                    md:right-15px md:top-15px
                "
                onClick={props.onDone}
            >
                <img src={closeIcon} alt="Close" title="Close" />
            </div>

            <div
                className="
                    relative mt-24px
                    md:mt-16px
                "
            >
                <PrimaryText typography="h3">Manage Therapists</PrimaryText>

                <PrimaryText typography="text" className="mt-16px">
                    Nirvana wants to make it easy for you to keep your therapist
                    information current. You may update, add, or remove
                    therapists from your Nirvana Reimbursement Calculator.
                    Updating a therapist’s Session Rate, Session Type, or
                    replacing one therapist with another will not affect your
                    subscription.
                    <br />
                    <br />
                    Adding or removing a therapist may alter your monthly
                    subscription rate. New therapists will be immediately added
                    to your subscription. Removed therapists will no longer be
                    included in your next monthly billing cycle. Please confirm
                    the subscription amounts below before saving your changes.
                </PrimaryText>

                <form
                    onSubmit={handleSubmit(onSaveHealthProviders)}
                    noValidate={true}
                >
                    <EmbedCalculatorEditTherapistsComponent
                        predefinedData={predefinedHealthProviders}
                        reactHookFormRegister={register}
                        reactHookFormUnregister={unregister}
                        reactHookFormErrors={errors}
                        reactHookFormSet={setValue}
                        reactHookFormControl={control}
                        reactHookFormWatch={watch}
                    />

                    <div className="mt-32px">
                        <ButtonElement
                            label="Save"
                            size="large"
                            htmlType="submit"
                            type="primary"
                            buttonClassName="w-full"
                            isLoading={!!runtimeState.isLoading.length}
                        />

                        <div className="text-center mt-16px">
                            <span
                                className="cursor-pointer no-underline!"
                                onClick={props.onDone}
                            >
                                Cancel
                            </span>
                        </div>
                    </div>
                </form>
            </div>
        </PopupWrapperElement>
    )
}
