/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import {
    AutoCompleteElement,
    BrowserStorageHelper,
    BrowserStorageType,
    ButtonElement,
    CodeCopyBlockElement,
    InputElement,
    LookupHelper,
    PrimaryText,
    SelectElement,
    useStateCallback,
    UtilHelper,
    VALIDATION_CONFIG,
    useIsMobile,
    useCptCodes,
    AvailableLookupConsumer,
} from "nirvana-react-elements"

import { CALCULATOR_CHECKOUT_CONFIG } from "../../config/calculatorCheckout.config"
import { LookupService } from "../../services/lookup.service"
import { GENERAL_CONFIG } from "../../config/general.config"
import { CalculatorCheckoutHelper } from "../../helpers/calculatorCheckout.helper"

import deleteIcon from "../../assets/images/icons/delete-dark.svg"
import userAddIcon from "../../assets/images/icons/user-add-dark.svg"
import plusIcon from "../../assets/images/icons/plus-dark.svg"

export const EmbedCalculatorEditTherapistsComponent: React.FunctionComponent<
    IEmbedCalculatorEditTherapistsComponentProps
> = props => {
    const [searchParams] = useSearchParams()

    const [isNirvanaAdmin] = useState<boolean>(
        !!searchParams.get(GENERAL_CONFIG.urlSearchParamsKeys.nirvanaAdmin),
    )

    const isMobile = useIsMobile()

    const { availableCptCodesStyled } = useCptCodes(
        AvailableLookupConsumer.embedCalculator,
    )

    const [
        predefinedInputIndexesProcessed,
        setPredefinedInputIndexesProcessed,
    ] = useStateCallback<boolean>(false)

    const [predefinedProcessed, setPredefinedProcessed] =
        useState<boolean>(false)

    const [generatedCustomerLink, setGeneratedCustomerLink] = useState<string>()

    // Set amount of rows and rates inside each therapist row
    // key -> index of therapist in array, aka "row"
    // value -> array of rate indexes of this therapist
    const [inputDataIndexes, setInputDataIndexes] = useStateCallback<{
        [key: number]: number[]
    }>({ 0: [0] })

    const [therapistsData, setTherapistsData] = useState<
        Partial<ICalculatorCheckoutSingleTherapistData>[]
    >([])
    const [stringifiedTherapistsData, setStringifiedTherapistsData] =
        useState<string>()

    // Watch for changes of data
    // We first put this new data to state and then save to browser storage / use in link generation
    // Using through callback because we need updates without re-render
    useEffect(() => {
        const { unsubscribe } = props.reactHookFormWatch(newValues => {
            try {
                if (
                    !newValues[
                        CALCULATOR_CHECKOUT_CONFIG.inputDataTherapistsKey
                    ]
                ) {
                    return
                }

                setTherapistsData(
                    newValues[
                        CALCULATOR_CHECKOUT_CONFIG.inputDataTherapistsKey
                    ],
                )

                setStringifiedTherapistsData(
                    JSON.stringify(
                        newValues[
                            CALCULATOR_CHECKOUT_CONFIG.inputDataTherapistsKey
                        ].filter(item => !!item), // filter so we are not adding empty values
                    ),
                )
            } catch (e) {}
        })

        return () => unsubscribe()
    }, [props.reactHookFormWatch, props.persistDataToBrowserStorage])

    // Watch for stringified therapist data and persist it to browser storage if needed
    // Stringified right away because useEffect is comparing references, so it's not working properly with arrays/objects sometimes
    useEffect(() => {
        if (
            !props.persistDataToBrowserStorage ||
            !stringifiedTherapistsData ||
            // If we've passed some data already but it wasn't processed
            (props.predefinedData?.length && !predefinedProcessed)
        ) {
            return
        }

        // Add debounce
        const timeoutId = setTimeout(() => {
            BrowserStorageHelper.set(
                GENERAL_CONFIG.browserStorageKeys.embedCheckoutConfiguredData,
                stringifiedTherapistsData,
                BrowserStorageType.sessionStorage,
            )
        }, 2 * 1000)

        return () => {
            try {
                clearTimeout(timeoutId)
            } catch (e) {}
        }
    }, [stringifiedTherapistsData])

    // Process predefined data, also set inputIndexes based on this
    useEffect(() => {
        if (!props.predefinedData?.length || predefinedProcessed) {
            return
        }

        const generateIndexesArray = (items: IIndexable[]): number[] => {
            return Array.from(
                { length: items.filter(item => !!item).length },
                (v, i) => i,
            )
        }

        if (!predefinedInputIndexesProcessed) {
            const predefinedIndexes = generateIndexesArray(props.predefinedData)

            // need also configure predefined rates indexes here
            const rateIndexes = {} as { [key: number]: number[] }

            predefinedIndexes.forEach(rowIndex => {
                const rowRates =
                    props.predefinedData?.[rowIndex]?.[
                        CALCULATOR_CHECKOUT_CONFIG.inputDataRatesKey
                    ]

                rateIndexes[rowIndex] = rowRates?.length
                    ? generateIndexesArray(rowRates)
                    : [0]
            })

            setPredefinedInputIndexesProcessed(true, () =>
                setInputDataIndexes(rateIndexes),
            )

            return
        }

        setPredefinedProcessed(true)

        // set predefined values for objects (like health provider in form state)
        for (const index of Object.keys(inputDataIndexes)) {
            const fieldGroupName = `${CALCULATOR_CHECKOUT_CONFIG.inputDataTherapistsKey}[${index}]`

            try {
                props.reactHookFormSet(
                    `${fieldGroupName}.healthProvider`,
                    props.predefinedData[index]?.healthProvider,
                )
            } catch (e) {
                console.log(e)
            }
        }
    }, [inputDataIndexes, props.predefinedData])

    const onAddRow = () => {
        const dataIndexes = Object.keys(inputDataIndexes).map(item => +item)

        if (
            dataIndexes.length >=
            CALCULATOR_CHECKOUT_CONFIG.maxHealthProvidersCount
        ) {
            return
        }

        const newRowIndex = Math.max(...dataIndexes, 0) + 1

        setInputDataIndexes(currentDataIndexes => ({
            ...currentDataIndexes,

            [newRowIndex]: [0],
        }))
    }

    const onRemoveRow = (rowIndex: number) => {
        const dataIndexes = Object.keys(inputDataIndexes).map(item => +item)

        if (
            dataIndexes.length <=
            CALCULATOR_CHECKOUT_CONFIG.minHealthProvidersCount
        ) {
            return
        }

        setInputDataIndexes(currentDataIndexes => {
            delete currentDataIndexes[rowIndex]

            // destructuring here so it creates new reference for state object
            // otherwise it's not rerendering
            return { ...currentDataIndexes }
        })
    }

    const onAddTherapistRate = (rowIndex: number, cptCodeValue: string) => {
        if (
            !inputDataIndexes[rowIndex] ||
            inputDataIndexes[rowIndex].length >= availableCptCodesStyled.length
        ) {
            return
        }

        const newRateIndex = Math.max(...inputDataIndexes[rowIndex], 0) + 1

        setInputDataIndexes(
            currentDataIndexes => {
                currentDataIndexes[rowIndex] = [
                    ...currentDataIndexes[rowIndex],
                    newRateIndex,
                ]

                // destructuring here so it creates new reference for state object
                // otherwise it's not rerendering
                return { ...currentDataIndexes }
            },
            () => {
                // set value of needed cptCode to dropdown
                setTimeout(() => {
                    props.reactHookFormSet(
                        `${
                            CALCULATOR_CHECKOUT_CONFIG.inputDataTherapistsKey
                        }[${rowIndex}][${[
                            CALCULATOR_CHECKOUT_CONFIG.inputDataRatesKey,
                        ]}][${newRateIndex}].cptCode`,
                        cptCodeValue,
                        {
                            shouldValidate: true,
                        },
                    )
                })
            },
        )
    }

    const onRemoveTherapistRate = (rowIndex: number, rateIndex: number) => {
        if (
            !inputDataIndexes[rowIndex] ||
            inputDataIndexes[rowIndex].length <= 1
        ) {
            return
        }

        setInputDataIndexes(currentDataIndexes => {
            currentDataIndexes[rowIndex] = currentDataIndexes[rowIndex].filter(
                item => item !== rateIndex,
            )

            // destructuring here so it creates new reference for state object
            // otherwise it's not rerendering
            return { ...currentDataIndexes }
        })
    }

    const generateCustomerLink = () => {
        if (!isNirvanaAdmin) {
            return
        }

        // Since some items can be empty if we remove
        const mappedInputData =
            CalculatorCheckoutHelper.generateUrlPredefinedCheckoutTherapistsData(
                therapistsData.filter(item => !!item),
            )

        if (!mappedInputData.length) {
            return
        }

        const customerLink = UtilHelper.appendQueryParams(
            UtilHelper.getCurrentAppDomain(),
            {
                [GENERAL_CONFIG.urlSearchParamsKeys.purchase]: 1,
                [GENERAL_CONFIG.urlSearchParamsKeys.embedCheckoutTherapists]:
                    JSON.stringify(mappedInputData),
            },
        )

        setGeneratedCustomerLink(customerLink)
    }

    const lookupHealthProviders = async (
        rowIndex: number,
        search?: string,
    ): Promise<IHealthProvider[]> => {
        // Filter already selected therapists from lookup
        let alreadySelectedTherapistIDs = [] as number[]

        try {
            // Ignore in selection therapists that are selected in other therapist rows
            alreadySelectedTherapistIDs = (
                (props.reactHookFormWatch(
                    CALCULATOR_CHECKOUT_CONFIG.inputDataTherapistsKey,
                ) as ICalculatorCheckoutSingleTherapistData[]) || []
            )
                .filter((item, index) => index !== rowIndex)
                .map(item => item.healthProvider?.id)
        } catch (e) {}

        return (await LookupService.lookupHealthProviders(search)).filter(
            item => !alreadySelectedTherapistIDs.includes(item.id),
        )
    }

    return availableCptCodesStyled.length ? (
        <div className={props.className}>
            {Object.keys(inputDataIndexes)
                .map(index => +index)
                .map(index => {
                    const fieldGroupName = `${CALCULATOR_CHECKOUT_CONFIG.inputDataTherapistsKey}[${index}]`
                    const fieldGroupErrors =
                        props.reactHookFormErrors?.[
                            CALCULATOR_CHECKOUT_CONFIG.inputDataTherapistsKey
                        ]?.[index]

                    const fieldGroupPredefinedData =
                        props.predefinedData?.[index]

                    // Disable options for current rates group that were already selected

                    const currentSelectedRowRates = (
                        props.reactHookFormWatch(
                            `${fieldGroupName}[${[
                                CALCULATOR_CHECKOUT_CONFIG.inputDataRatesKey,
                            ]}]`,
                        ) || []
                    ).map(item => item.cptCode)

                    const rowProcessedCptCodeOptions =
                        availableCptCodesStyled.map(item => ({
                            ...item,

                            disabled: currentSelectedRowRates.includes(
                                item.value,
                            ),
                        }))

                    return (
                        <div
                            key={index}
                            className={`
                                relative px-16px pb-16px rounded-8px bg-brand-warmLight
                                border border-solid border-brand-warmShadow mt-32px
                                ${
                                    Object.keys(inputDataIndexes).length >
                                    CALCULATOR_CHECKOUT_CONFIG.minHealthProvidersCount
                                        ? "pt-48px"
                                        : "pt-32px"
                                }
                            `}
                        >
                            <div className="flex items-start md:block">
                                <AutoCompleteElement
                                    className="flex-1"
                                    name="healthProvider"
                                    fieldGroupName={fieldGroupName}
                                    label="Therapist Name or NPI"
                                    dataFetcher={lookupHealthProviders.bind(
                                        {},
                                        index,
                                    )}
                                    itemRenderer={item =>
                                        LookupHelper.getRenderedHealthProvider(
                                            item,
                                            true,
                                            true,
                                            undefined,
                                            undefined,
                                            true,
                                        )
                                    }
                                    debounceMilliseconds={250}
                                    reactHookFormRegister={
                                        props.reactHookFormRegister
                                    }
                                    reactHookFormUnregister={
                                        props.reactHookFormUnregister
                                    }
                                    reactHookFormErrors={fieldGroupErrors}
                                    reactHookFormSet={props.reactHookFormSet}
                                    validations={{
                                        required: VALIDATION_CONFIG.required,
                                    }}
                                    defaultValue={
                                        fieldGroupPredefinedData?.healthProvider
                                            ? LookupHelper.getRenderedHealthProvider(
                                                  fieldGroupPredefinedData.healthProvider,
                                                  true,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  true,
                                              ).displayValue
                                            : undefined
                                    }
                                    shouldValidate
                                />
                            </div>

                            <div className="flex mt-40px">
                                <InputElement
                                    className="flex-1"
                                    label="Preferred Display Name (optional)"
                                    name="healthProviderPreferredName"
                                    fieldGroupName={fieldGroupName}
                                    reactHookControl={
                                        props.reactHookFormControl
                                    }
                                    shouldUnregister
                                    reactHookValidations={{
                                        minLength: VALIDATION_CONFIG.minLength,
                                        maxLength: VALIDATION_CONFIG.maxLength,
                                    }}
                                    reactHookErrors={fieldGroupErrors}
                                    defaultValue={
                                        fieldGroupPredefinedData?.healthProviderPreferredName
                                    }
                                />
                            </div>

                            {inputDataIndexes[index]?.map(rateIndex => {
                                const rateFieldGroupName = `${fieldGroupName}[${[
                                    CALCULATOR_CHECKOUT_CONFIG.inputDataRatesKey,
                                ]}][${rateIndex}]`

                                const ratesFieldGroupErrors =
                                    fieldGroupErrors?.[
                                        CALCULATOR_CHECKOUT_CONFIG
                                            .inputDataRatesKey
                                    ]?.[rateIndex]

                                const ratesFieldGroupPredefinedData =
                                    fieldGroupPredefinedData?.[
                                        CALCULATOR_CHECKOUT_CONFIG
                                            .inputDataRatesKey
                                    ]?.[rateIndex]

                                const isFirstRate =
                                    !inputDataIndexes[index].indexOf(rateIndex)

                                return (
                                    <div
                                        key={rateIndex}
                                        className={`
                                            relative flex items-start mr-36px
                                            sm:block
                                            ${
                                                isFirstRate
                                                    ? "mt-56px"
                                                    : "mt-16px"
                                            }
                                        `}
                                    >
                                        <SelectElement
                                            className="
                                                mr-16px flex-1 min-w-0
                                                sm:mr-0px sm:mt-40px
                                            "
                                            label={
                                                isFirstRate || isMobile
                                                    ? "Session Type"
                                                    : undefined
                                            }
                                            name="cptCode"
                                            fieldGroupName={rateFieldGroupName}
                                            reactHookFormRegister={
                                                props.reactHookFormRegister
                                            }
                                            reactHookFormUnregister={
                                                props.reactHookFormUnregister
                                            }
                                            reactHookFormSet={
                                                props.reactHookFormSet
                                            }
                                            reactHookFormErrors={
                                                ratesFieldGroupErrors
                                            }
                                            reactHookFormControl={
                                                props.reactHookFormControl
                                            }
                                            defaultValue={
                                                ratesFieldGroupPredefinedData?.cptCode ||
                                                CALCULATOR_CHECKOUT_CONFIG.defaultCptCode
                                            }
                                            validations={{
                                                required:
                                                    VALIDATION_CONFIG.required,
                                            }}
                                            options={rowProcessedCptCodeOptions}
                                        />

                                        <InputElement
                                            className="w-100px flex-shrink-0 sm:mt-10px sm:w-full"
                                            type="number"
                                            label={
                                                isFirstRate && !isMobile
                                                    ? "Session Rate"
                                                    : undefined
                                            }
                                            inputPrefix="$"
                                            name="sessionRate"
                                            fieldGroupName={rateFieldGroupName}
                                            reactHookControl={
                                                props.reactHookFormControl
                                            }
                                            shouldUnregister
                                            reactHookValidations={{
                                                required:
                                                    VALIDATION_CONFIG.required,
                                                min: VALIDATION_CONFIG.minAmount,
                                            }}
                                            reactHookErrors={
                                                ratesFieldGroupErrors
                                            }
                                            defaultValue={
                                                ratesFieldGroupPredefinedData?.sessionRate ||
                                                CALCULATOR_CHECKOUT_CONFIG.defaultHealthProviderRate.toString()
                                            }
                                        />

                                        {/*Remove rate icon*/}
                                        {inputDataIndexes[index].length > 1 && (
                                            <div className="absolute right--32px top-14px">
                                                <img
                                                    src={deleteIcon}
                                                    className="cursor-pointer"
                                                    alt="Remove"
                                                    onClick={() =>
                                                        onRemoveTherapistRate(
                                                            index,
                                                            rateIndex,
                                                        )
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>
                                )
                            })}

                            {rowProcessedCptCodeOptions.find(
                                item => !item.disabled,
                            ) && (
                                <SelectElement
                                    className="w-265px mt-16px"
                                    name={`addCptCode-${index}`}
                                    externalValue="Add another session type"
                                    selectResultPrefixIcon={plusIcon}
                                    options={rowProcessedCptCodeOptions}
                                    onSelected={(cptCodeValue: string) =>
                                        onAddTherapistRate(index, cptCodeValue)
                                    }
                                    short
                                />
                            )}

                            {/*REMOVE ROW*/}
                            {Object.keys(inputDataIndexes).length >
                            CALCULATOR_CHECKOUT_CONFIG.minHealthProvidersCount ? (
                                <div
                                    className="
                                        absolute top-0 right-0 p-8px flex items-center justify-center bg-brand-white rounded-bl-lg rounded-tr-lg
                                        border-l border-b border-solid border-brand-warmShadow
                                        hover:bg-brand-lilacLight
                                    "
                                >
                                    <img
                                        src={deleteIcon}
                                        className="cursor-pointer"
                                        alt="Remove"
                                        onClick={() => onRemoveRow(index)}
                                    />
                                </div>
                            ) : null}
                        </div>
                    )
                })}

            {/*ADD NEW ROW*/}
            <div className="flex mt-16px">
                <div className="flex-1" />

                {Object.keys(inputDataIndexes).length <
                CALCULATOR_CHECKOUT_CONFIG.maxHealthProvidersCount ? (
                    <ButtonElement
                        label="Add another therapist"
                        onClick={onAddRow}
                        type="default"
                        htmlType="button"
                        size="middle"
                        icon={userAddIcon}
                    />
                ) : null}
            </div>

            <div className="mt-32px flex justify-center p-16px rounded-8px bg-brand-warmLight">
                <div className="flex items-center md:block">
                    <div className="flex items-start mr-16px md:justify-center">
                        <PrimaryText typography="h3">
                            {UtilHelper.getFormattedMoney(
                                CalculatorCheckoutHelper.getSubscriptionGraduatedPricing(
                                    Object.keys(inputDataIndexes).length,
                                ),
                                true,
                            )}
                        </PrimaryText>

                        <PrimaryText typography="h3" className="ml-2px">
                            .
                            {UtilHelper.getCentsAmount(
                                CalculatorCheckoutHelper.getSubscriptionGraduatedPricing(
                                    Object.keys(inputDataIndexes).length,
                                ),
                            )}
                        </PrimaryText>
                    </div>

                    <PrimaryText typography="textSemibold" className="mt-10px">
                        Total Price per Month
                    </PrimaryText>
                </div>
            </div>

            {isNirvanaAdmin && (
                <div className="mt-32px rounded-8px bg-brand-warmLight p-16px">
                    <div className="text-center">
                        <ButtonElement
                            buttonClassName="w-full"
                            label="Generate Customer Link"
                            onClick={generateCustomerLink}
                            type="default"
                            htmlType="button"
                            size="middle"
                        />
                    </div>

                    {generatedCustomerLink && (
                        <CodeCopyBlockElement
                            className="mt-40px h-100px"
                            code={generatedCustomerLink}
                            language="text"
                        />
                    )}
                </div>
            )}
        </div>
    ) : null
}
