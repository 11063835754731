import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
    NIRVANA_COLORS,
    useScrollFix,
    PageHelmetElement,
    PrimaryText,
    ButtonElement,
    SpinnerElement,
} from "nirvana-react-elements"

import { ROUTES_CONFIG } from "../../config/routes.config"
import { LookupService } from "../../services/lookup.service"
import { GENERAL_CONFIG } from "../../config/general.config"

export const SupportedPayersListsComponent: React.FunctionComponent = () => {
    useScrollFix()

    // https://javascript.plainenglish.io/create-an-array-of-alphabet-characters-in-javascript-with-this-simple-trick-930033079dd3
    const [alphabetPointers, setAlphabetPointers] = useState<string[]>(
        Array.from(Array(26))
            .map((e, i) => i + 65)
            .map(x => String.fromCharCode(x)),
    )

    const [payers, setPayers] = useState<IPayer[]>([])
    const [isError, setIsError] = useState<boolean>(false)

    useEffect(() => {
        LookupService.lookupPayers(undefined, true)
            .then(payersLookup => {
                // Sort payers, so name with numbers appear at the end
                // By letters they are sorted from backend
                setPayers(
                    payersLookup.sort((a: IPayer, b: IPayer) => {
                        return (
                            +/[\d]/.test(a.insuranceName[0]) -
                            +/[\d]/.test(b.insuranceName[0])
                        )
                    }),
                )

                setIsError(false)
            })
            .catch(() => setIsError(true))
    }, [])

    // Once payers list loaded make sure to display only pointers alphabet with letters that have payers
    useEffect(() => {
        if (!payers.length) {
            return
        }

        const allPayersFirstLetters = payers.map(item => item.insuranceName[0])

        setAlphabetPointers(current =>
            current.filter(letter => allPayersFirstLetters.includes(letter)),
        )
    }, [payers])

    return (
        <div className="w-full">
            <div className="primary-container">
                <PageHelmetElement
                    title="Nirvana Health"
                    defaultPageTitle="Supported Insurers"
                />

                <div className="mt-24px flex items-center md:block">
                    <PrimaryText
                        className="flex-1 mr-20px md:mr-0px md:text-center"
                        typography="h1"
                    >
                        Nirvana Supported Insurers
                    </PrimaryText>

                    <div className="md:mt-30px md:text-center">
                        <Link to={ROUTES_CONFIG.calculatorUrl}>
                            <ButtonElement
                                label="Go to Out-of-Network Reimbursement Calculator"
                                type="primary"
                                size="middle"
                                htmlType="button"
                            />
                        </Link>
                    </div>
                </div>

                <div
                    className="
                        relative mt-80px pb-100px pt-30px bg-brand-offWhite rounded-12px
                        border border-solid border-brand-warmShadow min-h-130px
                        md:mt-50px
                    "
                >
                    {isError ? (
                        <PrimaryText
                            className="px-24px"
                            typography="h3"
                            centered
                        >
                            Oops... Something went wrong. Please try again or
                            contact{" "}
                            <a
                                href={`mailto:${GENERAL_CONFIG.supportEmail}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {GENERAL_CONFIG.supportEmail}
                            </a>{" "}
                            for assistance
                        </PrimaryText>
                    ) : payers.length ? (
                        <div>
                            {/*ALPHABET pointers*/}
                            <div className="flex flex-wrap sticky top-0 bg-brand-offWhite pb-10px px-24px">
                                {alphabetPointers.map((item, index) => (
                                    <a
                                        href={`#${item}`}
                                        key={index}
                                        className="mt-10px mr-15px"
                                    >
                                        <PrimaryText typography="h3">
                                            {item}
                                        </PrimaryText>
                                    </a>
                                ))}
                            </div>

                            {/*ACTUAL LIST*/}
                            {payers.map((item, index) => {
                                const withLetterDivider =
                                    !index ||
                                    item.insuranceName[0] !==
                                        payers[index - 1].insuranceName[0]

                                return (
                                    <div key={index}>
                                        {withLetterDivider && (
                                            <div
                                                id={item.insuranceName[0]}
                                                className="pt-50px mb-24px"
                                            >
                                                <PrimaryText
                                                    className="py-16px bg-brand-primary px-24px"
                                                    typography="h3"
                                                    color={
                                                        NIRVANA_COLORS.brand
                                                            .white
                                                    }
                                                >
                                                    {item.insuranceName[0]}
                                                </PrimaryText>
                                            </div>
                                        )}

                                        <div
                                            className="
                                                py-16px mx-24px
                                                border-b border-solid border-brand-lilacLight
                                            "
                                        >
                                            <PrimaryText typography="h4">
                                                {item.insuranceName}
                                            </PrimaryText>

                                            <PrimaryText
                                                className="mt-4px"
                                                typography="caption"
                                            >
                                                Insurer ID:{" "}
                                                <span className="text-bold">
                                                    {item.payerId}
                                                </span>
                                            </PrimaryText>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    ) : (
                        <SpinnerElement
                            containerClassName="text-center"
                            size="large"
                        />
                    )}
                </div>
            </div>
        </div>
    )
}
