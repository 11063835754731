import moment from "moment"
import { UtilHelper, IFileToUpload } from "nirvana-react-elements"

import { HttpHelper } from "../helpers/http.helper"
import { API_ROUTES } from "../config/routes.config"
import { GENERAL_CONFIG } from "config/general.config"

export class CalculatorService {
    /**
     * Save id card to S3 and starts manual eligibility check
     */
    static async startManualEligibility(data: {
        idCard: IFileToUpload
        inputData: ICalculatorInputData
        email: string
    }): Promise<any> {
        const newInputData = { ...data.inputData } as any

        newInputData.practiceId = data.inputData.practice?.id
        newInputData.payerId = data.inputData.payer.payerId
        newInputData.healthProviderId = data.inputData.healthProvider.id
        newInputData.dob = moment(data.inputData.dob).valueOf()

        delete newInputData.practice
        delete newInputData.payer
        delete newInputData.healthProvider

        const result = await HttpHelper.sendRequest(
            API_ROUTES.manualEligibilityUrl,
            {
                idCard: data.idCard,
                email: data.email,
                ...newInputData,
            },
            "POST",
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Get calculation data
     */
    static async getCalculationsData(
        inputData: ICalculatorInputData,
        embedKey?: string,
    ): Promise<ICalculationsResult | undefined> {
        const { firstName, lastName, sessionCharge, memberId, cptCode } =
            inputData

        const data = {
            firstName,
            lastName,
            dob: UtilHelper.dateToMysqlFormat(inputData.dob), // YYYY-MM-DD
            healthProviderId: inputData.healthProvider?.id,
            practiceId: inputData.practice?.id,
            payerId: inputData.payer.payerId,
            memberId,
            sessionCharge,
            cptCode,
        }

        const result = await HttpHelper.sendRequest(
            API_ROUTES.calculateUrl,
            data,
            "GET",
            embedKey
                ? {
                      [GENERAL_CONFIG.headers.embedKey]: embedKey,
                  }
                : undefined,
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }

    /**
     * Email results (generated pdf) to some email address
     */
    static async emailResults(
        email: string,
        resultingPdf: IFileToUpload,
    ): Promise<any> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.emailResultsUrl,
            {
                email,
                resultingPdf,
            },
            "POST",
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.errorData
            ? result
            : undefined
    }
}
