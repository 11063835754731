/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from "react"
import {
    PrimaryText,
    ButtonElement,
    InputElement,
    VALIDATION_CONFIG,
    CheckboxElement,
    INPUT_MASKS_CONFIG,
    AutoCompleteElement,
    LookupHelper,
} from "nirvana-react-elements"
import { useSelector } from "react-redux"
import { useForm } from "react-hook-form"

import { runtimeSelector } from "../../selectors/runtime.selector"
import { TOOLTIPS_CONFIG } from "../../config/tooltips.config"
import { LookupService } from "../../services/lookup.service"
import { GENERAL_CONFIG } from "../../config/general.config"

import safetyWhiteIcon from "../../assets/images/icons/safety-white.svg"
import mailIcon from "../../assets/images/icons/mail-dark.svg"

export const EmbedCalculatorEnrollmentFormComponent: React.FunctionComponent<{
    className?: string
    onDone: (data: IEmbedCalculatorClientEnrollmentData) => void
}> = props => {
    const runtimeState = useSelector(runtimeSelector)

    const {
        handleSubmit,
        control,
        formState: { errors },
        watch,
        register,
        setValue,
    } = useForm()

    const password: any = useRef({})
    password.current = watch("password", "")

    const [tosAccepted, setTosAccepted] = useState<boolean>(false)

    const onSubmit = (data: any) => {
        props.onDone(data as IEmbedCalculatorClientEnrollmentData)
    }

    return (
        <div
            className={`
                relative
                ${props.className}
            `}
        >
            <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
                {/*ACCOUNT DETAILS*/}
                <div>
                    <PrimaryText typography="h4">
                        Account Information
                    </PrimaryText>

                    <InputElement
                        className="mt-36px"
                        name="email"
                        label="Email Address"
                        reactHookControl={control}
                        reactHookErrors={errors}
                        reactHookValidations={{
                            required: VALIDATION_CONFIG.required,
                            pattern: VALIDATION_CONFIG.email,
                            maxLength: VALIDATION_CONFIG.maxLength,
                        }}
                        inputSuffix={
                            <img
                                src={mailIcon}
                                alt="Email address"
                                title="Email address"
                                className="ml-8px"
                            />
                        }
                    />

                    <div className="mt-36px flex items-start md:block">
                        <InputElement
                            className="mr-16px flex-1 md:mr-0px"
                            name="password"
                            label="Enter Password"
                            type="password"
                            reactHookControl={control}
                            reactHookErrors={errors}
                            reactHookValidations={{
                                required: VALIDATION_CONFIG.required,
                                validate:
                                    VALIDATION_CONFIG.passwordSpecialValidations,
                            }}
                        />

                        <InputElement
                            className="flex-1 md:mt-36px"
                            name="passwordConfirmation"
                            label="Confirm Password"
                            type="password"
                            reactHookControl={control}
                            reactHookErrors={errors}
                            reactHookValidations={{
                                validate: value =>
                                    value === password.current ||
                                    "The passwords do not match",
                            }}
                        />
                    </div>
                </div>

                {/*ACCOUNT DETAILS*/}
                <div className="mt-48px">
                    <PrimaryText typography="h4">
                        Personal Information
                    </PrimaryText>

                    <div className="flex items-start mt-36px md:block">
                        <InputElement
                            className="
                                mr-16px flex-1
                                md:mr-0px
                            "
                            label="First Name"
                            name="firstName"
                            tooltip={TOOLTIPS_CONFIG.calculator.firstName}
                            reactHookControl={control}
                            reactHookValidations={{
                                required: VALIDATION_CONFIG.required,
                                maxLength: VALIDATION_CONFIG.maxLength,
                            }}
                            reactHookErrors={errors}
                        />

                        <InputElement
                            className="flex-1 md:mt-36px"
                            label="Last Name"
                            name="lastName"
                            tooltip={TOOLTIPS_CONFIG.calculator.lastName}
                            reactHookControl={control}
                            reactHookValidations={{
                                required: VALIDATION_CONFIG.required,
                                maxLength: VALIDATION_CONFIG.maxLength,
                            }}
                            reactHookErrors={errors}
                        />
                    </div>

                    <div className="mt-36px flex items-center md:block">
                        <InputElement
                            className="flex-1 mr-20px md:mr-0px"
                            label="Date of Birth"
                            name="dob"
                            reactHookControl={control}
                            reactHookValidations={{
                                required: VALIDATION_CONFIG.required,
                                validate: VALIDATION_CONFIG.date,
                            }}
                            maskOptions={INPUT_MASKS_CONFIG.dob}
                            placeholder="mm/dd/yyyy"
                            mask={Date}
                            reactHookErrors={errors}
                        />

                        <div className="flex-1" />
                    </div>
                </div>

                {/*INSURANCE DETAILS*/}
                <div className="mt-48px">
                    <PrimaryText typography="h4">
                        Insurance Information
                    </PrimaryText>

                    <div
                        className="
                            flex items-start mt-36px
                            md:block
                        "
                    >
                        <div
                            className="
                                mr-16px flex-1
                                md:mr-0px
                            "
                        >
                            <AutoCompleteElement
                                name="payer"
                                label="Insurance Provider"
                                tooltip={
                                    TOOLTIPS_CONFIG.calculator.insuranceProvider
                                }
                                placeholder=""
                                dataFetcher={(search?: string) =>
                                    LookupService.lookupPayers(search)
                                }
                                itemRenderer={LookupHelper.getRenderedPayer}
                                reactHookFormErrors={errors}
                                reactHookFormRegister={register}
                                reactHookFormSet={setValue}
                                debounceMilliseconds={250}
                                validations={{
                                    required: VALIDATION_CONFIG.required,
                                }}
                                shouldValidate
                                notFoundTitle="Don’t see your insurance provider?"
                                notFoundSubtitle={`
                                    <a
                                        href="mailto:${GENERAL_CONFIG.supportEmail}?subject=Insurance provider not found"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Contact Support
                                    </a>
                                `}
                                notFoundAction={() => (
                                    <a
                                        href={
                                            GENERAL_CONFIG.supportedInsurersUrl
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <ButtonElement
                                            label="View Supported Payers"
                                            type="primary"
                                            htmlType="button"
                                            size="large"
                                        />
                                    </a>
                                )}
                            />

                            <a
                                href={GENERAL_CONFIG.supportedInsurersUrl}
                                target="_blank"
                                rel="noreferrer"
                                className="no-underline!"
                            >
                                <PrimaryText
                                    className="mt-8px"
                                    typography="captionSemibold"
                                    underline
                                >
                                    View all supported insurance providers
                                </PrimaryText>
                            </a>
                        </div>

                        <InputElement
                            className="
                                flex-1
                                md:mt-36px
                            "
                            label="Member ID"
                            name="memberId"
                            reactHookErrors={errors}
                            reactHookControl={control}
                            reactHookValidations={{
                                required: VALIDATION_CONFIG.required,
                            }}
                        />
                    </div>
                </div>

                {/*TOS AND POLICY ACCEPTANCE*/}
                <CheckboxElement
                    className="mt-32px flex items-start"
                    checkboxClassName="flex! items-start!"
                    name="tos"
                    onChange={setTosAccepted}
                    label={
                        <PrimaryText>
                            By checking this box and by clicking “Enroll in
                            Claims Filing” you agree to Nirvana Health’s{" "}
                            <a
                                href="https://www.meetnirvana.com/legal/patient-terms-and-conditions-of-use"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-small underline"
                            >
                                Terms and Conditions of Use
                            </a>
                            {", "}
                            <a
                                href="https://www.meetnirvana.com/legal/patient-portal-authorization"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-small underline"
                            >
                                Patient Portal Authorization
                            </a>
                            {" and "}
                            <a
                                href="https://www.meetnirvana.com/legal/privacy-policy"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-small underline"
                            >
                                Privacy Policy
                            </a>
                            .
                        </PrimaryText>
                    }
                />

                <div className="mt-16px flex items-center">
                    <div className="flex-1" />

                    <ButtonElement
                        label="Enroll in Claims Filing"
                        type="primary"
                        htmlType="submit"
                        size="large"
                        icon={safetyWhiteIcon}
                        isLoading={!!runtimeState.isLoading.length}
                        disabled={!tosAccepted}
                    />
                </div>
            </form>
        </div>
    )
}
