import { put, all, fork, takeLatest } from "redux-saga/effects"
import { call } from "typed-redux-saga"
import { IFileToUpload, UtilHelper } from "nirvana-react-elements"

import {
    calculatorActionCreators,
    CalculatorActions,
} from "../actions/calculator.actions"
import { runtimeActionCreators } from "../actions/runtime.actions"
import { ToastrHelper } from "../helpers/toastr.helper"
import { CalculatorService } from "../services/calculator.service"

////////////////////////////////////////////////////////////////////////////////////////// GET CLIENT COVERAGE
function* getCalculatorDataSaga() {
    yield takeLatest(
        CalculatorActions.CALCULATION_INPUT_DATA_SET,
        getCalculatorDataSagaHandler,
    )
}

function* getCalculatorDataSagaHandler<
    T extends ISagaAction & {
        payload: {
            inputData: ICalculatorInputData
            embedKey?: string
        }
    },
>(action: T) {
    yield put(
        runtimeActionCreators.startLoading("getCalculatorDataSagaHandler"),
    )

    try {
        yield put(calculatorActionCreators.resetCalculatorData())
        yield put(calculatorActionCreators.setProcessed(false))

        // Sleep for X seconds here so breathing spinner stays longer during this process
        yield call(UtilHelper.sleep, 3)

        const result = yield* call(
            CalculatorService.getCalculationsData,
            action.payload.inputData,
            action.payload.embedKey,
        )

        if (result) {
            // IF payer was overridden during check, show toast about this
            if (
                result.payer &&
                result.overriddenPayer &&
                result.modalityCoverageStatusTernary
            ) {
                ToastrHelper.success(
                    `Your mental health benefits are active under another insurer, ${result.payer.insuranceName}.`,
                )
            }

            yield put(calculatorActionCreators.setCalculatorData(result))

            action.onSuccess && action.onSuccess(result.resultType)

            yield put(calculatorActionCreators.setProcessed(true))
        } else {
            action.onError && action.onError(result)
        }
    } catch (e) {
        ToastrHelper.error(
            "Something went wrong during getting calculator data",
        )
    } finally {
        yield put(
            runtimeActionCreators.stopLoading("getCalculatorDataSagaHandler"),
        )
    }
}
////////////////////////////////////////////////////////////////////////////////////////// GET CLIENT COVERAGE

////////////////////////////////////////////////////////////////////////////////////////// SEND REQUEST WITH ID CARD TO CHECK COVERAGE
function* startManualEligibilityFlowSaga() {
    yield takeLatest(
        CalculatorActions.CALCULATOR_MANUAL_ELIGIBILITY_DATA_SET,
        startManualEligibilityFlowSagaHandler,
    )
}

function* startManualEligibilityFlowSagaHandler<
    T extends ISagaAction & {
        payload: IManualEligibilityData
    },
>(action: T) {
    yield put(
        runtimeActionCreators.startLoading(
            "startManualEligibilityFlowSagaHandler",
        ),
    )

    try {
        const result = yield* call(
            CalculatorService.startManualEligibility,
            action.payload,
        )

        if (result) {
            action.onSuccess && action.onSuccess(result)

            ToastrHelper.success(
                "Thank you, our team received your request and will get back to you shortly",
            )
        } else {
            action.onError && action.onError(result)

            ToastrHelper.error(
                "There was a problem processing your request, please try again later.",
            )
        }
    } catch (e) {
    } finally {
        yield put(
            runtimeActionCreators.stopLoading(
                "startManualEligibilityFlowSagaHandler",
            ),
        )
    }
}
////////////////////////////////////////////////////////////////////////////////////////// SEND REQUEST WITH ID CARD TO CHECK COVERAGE

////////////////////////////////////////////////////////////////////////////////////////// EMAIL RESULTS PDF TO SOME EMAIL
function* emailResultsSaga() {
    yield takeLatest(
        CalculatorActions.CALCULATOR_EMAIL_RESULTS,
        emailResultsSagaHandler,
    )
}

function* emailResultsSagaHandler<
    T extends ISagaAction & {
        payload: {
            email: string
            resultingPdf: IFileToUpload
        }
    },
>(action: T) {
    yield put(runtimeActionCreators.startLoading("emailResultsSagaHandler"))

    try {
        const result = yield* call(
            CalculatorService.emailResults,
            action.payload.email,
            action.payload.resultingPdf,
        )

        if (result) {
            action.onSuccess && action.onSuccess(result)

            ToastrHelper.success(
                "Thank you. Email with results was successfully sent to provided email address",
            )
        } else {
            action.onError && action.onError(result)

            ToastrHelper.error(
                "There was a problem processing your request, please try again later.",
            )
        }
    } catch (e) {
    } finally {
        yield put(runtimeActionCreators.stopLoading("emailResultsSagaHandler"))
    }
}
////////////////////////////////////////////////////////////////////////////////////////// EMAIL RESULTS PDF TO SOME EMAIL

export default function* rootSaga() {
    yield all([
        fork(getCalculatorDataSaga),
        fork(startManualEligibilityFlowSaga),
        fork(emailResultsSaga),
    ])
}
