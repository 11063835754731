/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react"
import {
    IStepperStepProps,
    PrimaryText,
    InputElement,
    VALIDATION_CONFIG,
    ButtonElement,
    NirvanaBreathingLoaderElement,
    AutoCompleteElement,
    LookupHelper,
} from "nirvana-react-elements"
import { useForm } from "react-hook-form"
import { useSelector } from "react-redux"

import { runtimeSelector } from "../../../selectors/runtime.selector"
import { RuntimeHelper } from "../../../helpers/runtime.helper"
import { LookupService } from "../../../services/lookup.service"

import searchIcon from "../../../assets/images/intakeDemo/search.svg"

export const InsuranceDataStep: React.FunctionComponent<
    IStepperStepProps
> = props => {
    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        register,
    } = useForm()

    const runtimeState = useSelector(runtimeSelector)

    const isGetCoverageLoading = useMemo<boolean>(
        () => RuntimeHelper.isIntakeDemoGetCoverageLoading(),
        [runtimeState.isLoading],
    )

    const onSubmit = (data: any) => {
        props.onStepSubmit(data as IGetCoverageClientStepData)
    }

    return (
        <div className="relative">
            <PrimaryText typography="h3" className="pb-24px">
                Hmm... we’re having <br /> trouble verifying your <br />{" "}
                eligibility.
            </PrimaryText>

            <PrimaryText className="mt-24px">
                Let’s try again with some more information. <br />
                Please provide your insurer and member ID.
            </PrimaryText>

            <NirvanaBreathingLoaderElement isActive={isGetCoverageLoading} />

            <form onSubmit={handleSubmit(onSubmit)}>
                <AutoCompleteElement
                    className="mt-44px"
                    name="payer"
                    label="Insurer"
                    placeholder=""
                    dataFetcher={(search?: string) =>
                        LookupService.lookupPayers(search)
                    }
                    itemRenderer={LookupHelper.getRenderedPayer}
                    reactHookFormErrors={errors}
                    reactHookFormRegister={register}
                    reactHookFormSet={setValue}
                    debounceMilliseconds={250}
                    validations={{
                        required: VALIDATION_CONFIG.required,
                    }}
                    shouldValidate
                    inputSuffix={searchIcon}
                />

                <InputElement
                    className="mt-44px"
                    label="Your Member ID #"
                    name="memberId"
                    reactHookErrors={errors}
                    reactHookControl={control}
                    reactHookValidations={{
                        required: VALIDATION_CONFIG.required,
                    }}
                />

                <div className="fixed z-10 left-0 bottom-0 p-24px w-full bg-brand-offWhite">
                    <ButtonElement
                        label="Search again"
                        type="primary"
                        size="large"
                        htmlType="submit"
                        buttonClassName="w-full"
                    />
                </div>
            </form>
        </div>
    )
}
